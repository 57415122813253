<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
import tableData from "@/components/tablecomponent";
import btnComponent from "@/components/btnComponent";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
/**
 * Advanced-table component
 */

export default {
  page: {
    title: "ใบวิเคราะห์ปัญหา",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    tableData,
    btnComponent,
    Multiselect,
    DatePicker,
  },
  data() {
    return {
      title: "ใบวิเคราะห์ปัญหา",
      items: [
        {
          text: "บริการ",
        },
        {
          text: "ใบวิเคราะห์ปัญหา",
        },
      ],

      localDataBranch: [],
      localData: [],
      overlayFlag: false,
      loading: undefined,
      total_pages: "",
      page: "",
      per_page: "",
      total: "",
      rowData: [], //? Data ที่ต่อ API
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25, 50, 100],
      // filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      paginatedData: "",
      selectedUsers: [],
      isSelectedAll: false,
      selectMode: "multi",
      selected: [],
      branchIdSearch: [],
      sv: "",
      type: "",
      date: "",
      optionType: [
        {
          type: "GS",
        },
        {
          type: "BP",
        },
        {
          type: "SLA",
        },
      ],
      filter: [
        {
          nameTh: "",
          brands: "",
          vehicleModel: "",
        },
      ],

      // packageId,
      optionsBrand: [],
      optionsVehicles: [],
      optionSV: [],

      fields: [
        {
          key: "diagCode",
          sortable: true,
          label: "รหัสใบวิเคราะห์ปัญหา",
        },
        {
          key: "createdBy",
          sortable: true,
          label: "เพิ่มโดย",
        },
        {
          key: "diagType",
          sortable: true,
          label: "ประเภท",
        },

        {
          key: "action",
          label: "แก้ไข | ลบ",
        },
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    //
  },
  mounted() {
    // Set the initial number of items
    // this.totalRows = this.rowData.length;
  },

  created() {
    this.getLocalData();
  },
  methods: {
    postDiag() {
      const bodyFormData = new FormData();
      bodyFormData.append("svId", this.sv.svId);
      bodyFormData.append("diagDate", this.date);
      bodyFormData.append("diagType", this.type.type);
      useNetw
        .post("api/service/diagnosis/store", bodyFormData)
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            // console.log(this.type.typeId),
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$router.push({
            name: "diag-car",
            params: {
              svId: this.sv.svId,
              diagId: response.data.diagId,
            },
          });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.$refs.modalPost.hide();
        });
    },
    popupmodalAdd() {
      this.$refs["modalPost"].show();
    },
    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      const localDataBranch = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item.branchId);
      });

      this.localDataBranch = localDataBranch;
      this.branchId = user.branchId;
      // console.log(this.localDataBranch);
    },
    handleSearch() {
      this.getData();

      //
    },
    customLabel({ svDate, svCode, licensePlate }) {
      return `${svCode} : [${licensePlate}] - วันที่ ${svDate}`;
    },

    getData: function() {
      this.overlayFlag = true;
      useNetw
        .get("api/service/diagnosis", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            svId: this.sv.svId, //this.svId
            // nameTh: "ใบวิเคราะห์ปัญหา",
          },
        }) // ?เอามาจากไหน

        .then((response) => {
          //?ได้รับการตอบกลับ
          //?ถ้าได้ข้อมูลมาหรือได้รับการตอบกลับให้ทำอะไร

          this.rowData = response.data.data; //?ข้อมูล
          this.per_page = response.data.per_page; // ?จำนวนข้อมูลที่แสดงต่อหน้า
          this.page = response.data.page; // ?หน้าที่เท่าไหร่
          this.total_pages = response.data.total_pages; //?หน้าทั้งหมด
          this.total = response.data.total; //? จำนวนข้อมูลทั้งหมด
          console.log(response.data.data[0]);
        })
        .catch((err) => {
          //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    getDataSV: function() {
      this.overlayFlag = true;
      useNetw
        .get("api/service", {
          params: {
            page: 1,
            perPage: 100,
            branchId: [this.branchIdSearch.branchId],
          },
        }) // ?เอามาจากไหน

        .then((response) => {
          this.optionSV = response.data.data;
          console.log(this.optionSV);
        })
        .catch((err) => {
          //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },

    handleChangePage(page) {
      this.currentPage = page;
      this.getData();
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getData();
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      // this.currentPage = 1;
    },

    onRowSelected(rowData) {
      this.selected = rowData;
    },
    ConfirmDelPackage(diagId, svId) {
      Swal.fire({
        title: "ต้องการลบใช่หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "ลบ",
      }).then((result) => {
        if (result.value) {
          this.deletePackage(diagId, svId);
        }
      });
    },

    deletePackage: function(diagId, svId) {
      // this.packageId = rowData;
      this.overlayFlag = true; //skeleton true
      useNetw
        .delete("api/service/diagnosis/delete", {
          data: {
            svId: svId,
            diagId: diagId,
          },
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.getData();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.overlayFlag = false; //skeleton false
        });
    },
    onSearch() {
      if (
        this.branchIdSearch == "" ||
        this.branchIdSearch == undefined ||
        this.branchIdSearch.length == 0
      ) {
        this.branchId = this.localData[0].branchId;
      } else {
        this.branchId = this.branchIdSearch.branchId;
      }
      // console.log(this.branchId);
      this.getData();
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="card-title">ข้อมูลแพคเกจในระบบ</h4> -->

            <div class="row">
              <div class="col-md-4">
                <div class="mb-3 position-relative">
                  <label for="branchId"><code>* </code>สาขา</label><br />
                  <multiselect
                    v-model="branchIdSearch"
                    :options="localData"
                    label="nameTh"
                    @input="getDataSV()"
                  >
                  </multiselect>
                </div>
              </div>

              <div class="col-md-8">
                <label>&nbsp;</label>
                ใบ Sv :
                <multiselect
                  v-model="sv"
                  :options="optionSV"
                  :custom-label="customLabel"
                  @input="getData()"
                >
                </multiselect>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 text-end">
                <label>&nbsp;</label><br />
                <btnComponent
                  @click="onSearch"
                  changeStyle="search"
                ></btnComponent
                >&nbsp;

                <button class="btn btn-primary" @click="popupmodalAdd()">
                  <i class="uil uil-plus"> </i>เพิ่ม
                </button>
              </div>
            </div>

            <br />

            <div class="row">
              <div class="col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    แสดงผล&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                      @input="handlePageChange"
                    ></b-form-select
                    >&nbsp;รายการ
                  </label>
                </div>
              </div>
            </div>
            <!-- End search -->
            <b-overlay
              :show="overlayFlag"
              :spinner-variant="this.$overlayVariant"
              :spinner-type="this.$overlayType"
              :rounded="this.$overlayRounded"
            >
              <tableData :fields="fields" :items="rowData">
                <template #cell(action)="rowData">
                  <ul class="list-inline mb-0">
                    <li class="list-inline-item">
                      <router-link
                        href="javascript:void(0);"
                        class="px-2 text-primary"
                        v-b-tooltip.hover
                        :to="{
                          name: 'diag-car',
                          params: { svId: sv.svId ,
                          diagId: rowData.item.diagId},
                        }"
                        title="Edit"
                      >
                        <i class="uil uil-pen font-size-18"></i>
                      </router-link>
                    </li>
                    <li class="list-inline-item">
                      <a
                        href="javascript:void(0);"
                        class="px-2 text-danger"
                        v-b-tooltip.hover
                        title="Delete"
                        @click="ConfirmDelPackage(rowData.item.diagId, sv.svId)"
                      >
                        <i class="uil uil-trash-alt font-size-18"></i>
                      </a>
                    </li>
                  </ul>
                </template>
              </tableData>
              <div class="row">
                <div class="col">
                  <div class="align-items-center" v-if="rowData.length > 0">
                    แสดงผล {{ perPage }} หน้า {{ currentPage }} จาก
                    {{ this.total }} รายการ
                  </div>
                  <div class="align-items-center text-center" v-else>
                    ไม่พบข้อมูลใบวิเคราห์ปัญหา
                  </div>
                  <div
                    class="dataTables_paginate paging_simple_numbers float-end"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="total"
                        :per-page="perPage"
                        @change="handleChangePage"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </b-overlay>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      modal-class="modal-fullscreen"
      size="xl"
      ref="modalPost"
      hide-footer
      title="เพิ่มใบวิเคราะห์ปัญหา"
    >
      <div class="row">
        <div class="col-md-4">
          <div class="mb-3 position-relative">
            <label for="branchId"><code>* </code>สาขา</label><br />
            <multiselect
              v-model="branchIdSearch"
              :options="localData"
              label="nameTh"
              @input="getDataSV()"
            >
            </multiselect>
          </div>
        </div>

        <div class="col-md-8">
          <label>&nbsp;</label>
          ใบ Sv :
          <multiselect
            v-model="sv"
            :options="optionSV"
            :custom-label="customLabel"
          >
          </multiselect>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <label for="date">วันที่ :</label>
          <date-picker
            v-model="date"
            format="YYYY-MM-DD"
            value-type="format"
          ></date-picker>
        </div>

        <div class="col-md-3">
          <label>&nbsp;</label>
          ประเภท :
          <multiselect v-model="type" :options="optionType" label="type">
          </multiselect>
        </div>
      </div>

      <hr />
      <div class="text-end">
        <button
          class="btn btn-success "
          :disabled="sv == '' || type == '' || date == ''"
          @click="postDiag()"
        >
          เพิ่ม
        </button>
      </div>
    </b-modal>
  </Layout>
</template>
